<template>
  <v-container>
    <v-row justify="space-between">
      <v-col class="mt-4" cols="6" align="right">
        <v-progress-circular
          :width="3"
          color="green"
          :indeterminate="load"
          v-if="showLoad"
          class="mt-5"
        ></v-progress-circular>
      </v-col>
      <!-- <v-col cols="6" align="right">
        <v-btn-toggle v-model="timeStatus" color="primary">
          <v-btn text small style="height: 50px" value="week">Weekly</v-btn>
          <v-btn text small style="height: 50px" value="month"> Monthly </v-btn>

          <v-btn text small style="height: 50px" value="quarterly">
            Quarterly
          </v-btn>
          <v-btn text small style="height: 50px" value="year"> 1 Year </v-btn>
        </v-btn-toggle>
      </v-col> -->
    </v-row>
    <!-- <v-row>
      <v-col v-if="userDetails.type === 'professional'">
        <v-row class="mt-n10">
          <v-col cols="3">
           
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mb-n4
                elevation-1
              "
              color="white"
              flat
              
            >
            
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ userEarning.user_revenue }}
                    <v-list-item-subtitle>
                      <span class="black--text">Revenue </span> 
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span>Revenue </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
             
            </v-card>
              
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mb-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ userEarning.user_total_withdrawal }}
                    <v-list-item-subtitle>
                      <span class="black--text">Withdrawn </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Amount Withdrawn by the User </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mb-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £
                    {{ userEarning.user_revenue * 0.25 }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Commission </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Commission Paid by the professional </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mb-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ userEarning.user_revenue * 0.75 }}
                    <v-list-item-subtitle>
                      <span class="black--text">Earning </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Earning of the professional </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="userStats">
        <v-row class="mt-n10">
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mb-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ customDetails.wallet.toFixed(2) }}
                    <v-list-item-subtitle>
                      <span class="black--text">Balance </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Balance of the professional in Wallet </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ userEarning.invited_user_earning }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Referral Bonus </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Amount Earn by Inviting Users </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ userStats.user_meeting_stats.user_meeting_total_count }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Total Meeting </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      userStats.user_meeting_stats.user_meeting_completed_count
                    }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Completed Meeting </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Completed Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                elevation-1
                mt-n4
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      userStats.user_meeting_stats.user_meeting_pending_count
                    }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Pending Meeting </span><v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Pending Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                elevation-1
                mt-n4
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      userStats.user_meeting_stats.user_meeting_cancelled_count
                    }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Cancelled Meeting </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Cancelled Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      userStats.user_meeting_stats.user_meeting_expired_count
                    }}

                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Expired Meeting </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Expired Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      userStats.user_meeting_stats.user_meeting_declined_count
                    }}

                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Declined Meeting </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Declined Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      userStats.user_meeting_stats.user_meeting_approved_count
                    }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Approved Meeting </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Approved Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ userEarning.invited_user_count }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Invited Users </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Invited Users </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      userStats.user_meeting_stats
                        .user_meeting_Due_payment_count
                    }}

                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Due Payment Meeting</span>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col> 
        </v-row>
      </v-col>
    </v-row>  -->
    <v-row>
      <v-col cols="8">
        <v-app style="background-color: #eeeeee;">
          <v-container>
            <v-row>
              <v-col cols="12">
                <base-material-card color="primary" class="pb-10">
                  <template v-slot:heading>
                    <div class="text-h3 font-weight-light">Vehicles</div>
                  </template>
                  <v-card
                    v-if="userDetails"
                    elevation="0"
                    class="pa-2 mt-n2"
                  >
                    <template>
                      <v-data-table
                        :headers="headersVehicle"
                        :items="VehicleDetails"
                        :items-per-page="5"
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogVehicle" max-width="600px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  New Item
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">
                                    {{ formTitleVehicle }}
                                  </span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemVehicle.make"
                                          label="Make"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="
                                            editedItemVehicle.registration
                                          "
                                          label="Registration"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeVehicle"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveVehicle()"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteVehicle"
                              max-width="600px"
                            >
                              <v-card>
                                <v-card-title class="text-h5">
                                  Are you sure you want to delete?
                                </v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteVehicle"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="
                                      deleteItemConfirmVehicle(
                                        editedItemVehicle.id,
                                      )
                                    "
                                  >
                                    OK
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.image_Vehicle`]="{ item }">
                          <v-avatar size="50px">
                            <img
                              v-if="item.image_Vehicle == null"
                              src="@/assets/study.png"
                              alt="Vehiclecation institue image"
                            />
                            <img
                              v-if="item.image_Vehicle"
                              :src="item.image_Vehicle"
                              alt="Vehiclecation institue image"
                            />
                          </v-avatar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="editItemVehicle(item)"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="deleteItemVehicle(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </template>
                  </v-card>
                </base-material-card>
              </v-col>

              <v-col cols="12">
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <div class="text-h3 font-weight-light">Recent Bookings</div>
                  </template>
                  <v-card v-if="userDetails" elevation="0" class="pa-2 mt-n15">
                    <template>
                      <v-data-table
                        :headers="headers"
                        :items="bookings"
                        :items-per-page="5"
                        sort-by="calories"
                        class="elevation-1"
                      >
                        <template v-slot:[`item.pickupAdr`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                {{ getShortAddress(item.pickupAdr) }}
                              </div>
                            </template>
                            <span>{{ item.pickupAdr }}</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.dropoffAdr`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                {{ getShortAddress(item.dropoffAdr) }}
                              </div>
                            </template>
                            <span>{{ item.dropoffAdr }}</span>
                          </v-tooltip>
                        </template>

                        <template v-slot:top>
                            <v-toolbar flat>
                              <v-spacer></v-spacer>
                              <v-dialog v-model="dialog" max-width="500px">
                                <v-card>
                                  <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                  </v-card-title>

                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-text-field
                                            v-model="editedItem.job"
                                            label="Job Title"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-text-field
                                            v-model="editedItem.location"
                                            label="Location"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                          <v-text-field
                                            v-model="editedItem.company"
                                            label="Company"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6" md="6">
                                          <v-dialog
                                            ref="menuexp"
                                            v-model="menuexp"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            persistent
                                            width="290px"
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-text-field
                                                v-model="editedItem.start_date"
                                                label="Start Date"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker
                                              v-model="editedItem.start_date"
                                              type="month"
                                              :active-picker.sync="activePickerexp
                                                "
                                              :max="new Date(
                                                Date.now() -
                                                new Date().getTimezoneOffset() *
                                                60000
                                              )
                                                  .toISOString()
                                                  .substr(0, 10)
                                                "
                                              min="1950-01-01"
                                            >
                                              <v-spacer></v-spacer>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="menuexp = false"
                                              >
                                                Cancel
                                              </v-btn>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="
                                                  $refs.menuexp.save(
                                                    editedItem.start_date
                                                  )
                                                  "
                                              >
                                                OK
                                              </v-btn>
                                            </v-date-picker>
                                          </v-dialog>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          sm="6"
                                          md="6"
                                          v-if="editedItem.current_job == true"
                                        ></v-col>
                                        <v-col
                                          cols="12"
                                          sm="6"
                                          md="6"
                                          v-if="editedItem.current_job == false"
                                        >
                                          <v-dialog
                                            ref="menuexpend"
                                            v-model="menuexpend"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            persistent
                                            offset-y
                                            width="290px"
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-text-field
                                                v-model="editedItem.end_date"
                                                label="End Date"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker
                                              v-model="editedItem.end_date"
                                              type="month"
                                              :active-picker.sync="activePickerexpend
                                                "
                                              :max="new Date(
                                                Date.now() -
                                                new Date().getTimezoneOffset() *
                                                60000
                                              )
                                                  .toISOString()
                                                  .substr(0, 10)
                                                "
                                              min="1950-01-01"
                                            >
                                              <v-spacer></v-spacer>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="menuexpend = false"
                                              >
                                                Cancel
                                              </v-btn>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="
                                                  $refs.menuexpend.save(
                                                    editedItem.end_date
                                                  )
                                                  "
                                              >
                                                OK
                                              </v-btn>
                                            </v-date-picker>
                                          </v-dialog>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-checkbox
                                            v-model="editedItem.current_job"
                                            label="Currently Working"
                                          ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-file-input
                                            v-model="editedItem.image_exp"
                                            :rules="[
                                              (value) =>
                                                !value ||
                                                value.size < 2000000 ||
                                                'Image size should be less than 2 MB!',
                                            ]"
                                            accept="image/png, image/jpeg, image/bmp"
                                            placeholder="Pick an Image"
                                            prepend-icon="mdi-camera"
                                            label="Institute Image"
                                          />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                          <v-textarea
                                            v-model="editedItem.description"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            label="Description"
                                            :value="editedItem.description"
                                          ></v-textarea>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="close"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="save"
                                    >
                                      Save
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <template>
                                <v-dialog
                                  v-model="dialogDelete"
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title class="text-h5">
                                      Are you sure you want to delete?
                                    </v-card-title>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDelete"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItemConfirm(editedItem.id)"
                                      >
                                        OK
                                      </v-btn>
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </template>
                            </v-toolbar>
                          </template>


                      </v-data-table>
                    </template>
                  </v-card>
                </base-material-card>
              </v-col>
              <!-- <v-col cols="12">
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <div class="text-h3 font-weight-light">Invited Users</div>
                  </template>
                  <v-card
                    v-if="userDetails"
                    elevation="0"
                    class="pa-2"
                    style="height: 405px"
                  >
                    <template>
                      <v-data-table
                        :headers="headersInvt"
                        :items="InvtDetails"
                        :items-per-page="5"
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogDeleteInvt"
                              max-width="600px"
                            >
                              <v-card>
                                <v-card-title class="text-h5"
                                  >Are you sure you want to
                                  delete?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteInvt"
                                    >Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="
                                      deleteItemConfirmInvt(editedItemInvt.id)
                                    "
                                    >OK
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="goToProfile(item.id)"
                          >
                            mdi-eye
                          </v-icon>
                        </template>
                      </v-data-table>
                    </template>
                  </v-card>
                </base-material-card>
              </v-col> -->
            </v-row>
          </v-container>
        </v-app>
      </v-col>
      <v-col cols="4">
        <v-app style="background-color: #eeeeee;">
          <v-container>
            <v-row>
              <v-col cols="12">
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <div class="text-h3 font-weight-light">General Details</div>
                  </template>
                  <v-card v-if="userDetails" elevation="0" class="pa-2">
                    <template v-if="customDetails">
                      <v-form>
                        <v-col md="6" style="margin-top: -17px">
                          <v-avatar
                            size="130px"
                            style="margin-left: 60px; margin-top: -25px"
                            v-if="showFileInput == false && userDetailsEdit"
                            :key="customDetails.profile_photo"
                          >
                            <img
                              v-if="customDetails.profile_photo == null"
                              src="@/assets/user.png"
                              style="margin-left: 35px"
                            />
                            <img
                              v-else
                              :src="customDetails.profile_photo"
                              alt="User Image"
                            />
                          </v-avatar>
                          <v-btn
                            color="primary"
                            x-small
                            fab
                            dark
                            style="margin-top: -250px; margin-left: 160px"
                            v-if="showFileInput == false && userDetailsEdit"
                            @click="openFileInput"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <input
                            type="file"
                            ref="avatarFileInput"
                            style="display: none"
                            accept="image/*"
                            @change="onAvatarFileChange"
                          />
                        </v-col>
                        <v-row
                          class="no-gutters py-2"
                          style="margin-bottom: 25px;"
                          v-if="userDetailsEdit"
                        >
                          <v-col md="6">
                            <h5>FirstName :</h5>
                            <v-text-field
                              v-model="customDetails.firstname"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px;"
                            />
                          </v-col>
                          <v-col md="6">
                            <h5>LastName :</h5>
                            <v-text-field
                              v-model="customDetails.lastname"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px;"
                            />
                          </v-col>
                          <!-- <v-col md="6">
                              <h5>Raiting :</h5>
                              <v-text-field
                                v-model="customDetails.rating"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>Availability :</h5>
                              <v-text-field
                                v-model="customDetails.availability"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                              />
                            </v-col> -->
                          <!-- <v-col md="4">
                            <h5>Status :</h5>
                            <v-text-field
                              v-model="customDetails.status"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px"
                            />
                          </v-col>
                          <v-col md="6">
                            <h5>Type :</h5>
                            <v-text-field
                              v-model="customDetails.type"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px"
                              readonly
                            />
                          </v-col> -->
                          <v-col md="12">
                            <h5>Email :</h5>
                            <v-text-field
                              v-model="customDetails.email"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px;"
                            />
                          </v-col>

                          <v-col md="12">
                            <h5>Phone :</h5>
                            <v-text-field
                              v-model="customDetails.phone"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px;"
                            />
                          </v-col>
                          <!-- <v-col md="6">
                           <v-checkbox
                              v-model="customDetails.is_featured"
                              label="Featured"
                            />
                          </v-col>
                          <v-col md="12">
                            <h5>Bio</h5>
                            <v-textarea
                              v-model="customDetails.bio"
                              rows="8"
                              :value="customDetails.bio"
                            ></v-textarea>
                          </v-col> -->
                          <v-col md="8">
                            <v-file-input
                              v-model="customDetails.profile_photo"
                              :rules="[
                                (value) =>
                                  !value ||
                                  value.size < 2000000 ||
                                  'Image size should be less than 2 MB!',
                              ]"
                              accept="image/png, image/jpeg, image/bmp"
                              placeholder="Pick an Image"
                              prepend-icon="mdi-camera"
                              label="User Image"
                              v-if="showFileInput"
                            />
                          </v-col>
                        </v-row>
                        <template v-if="userDetailsEdit != true">
                          <v-col md="6">
                            <v-avatar
                              size="130px"
                              style="margin-top: -60px; margin-left: 60px;"
                            >
                              <img
                                v-if="customDetails.profile_photo == null"
                                src="@/assets/user.png"
                                style="margin-left: 65px;"
                              />
                              <img
                                :src="customDetails.profile_photo"
                                alt="useriamge"
                              />
                            </v-avatar>
                          </v-col>
                          <!-- <v-col>
                            <div style="margin-top: -10px; margin-left: 80px">
                              <span
                                ><u>
                                  Balance:
                                  <strong
                                    >£
                                    {{
                                      customDetails.wallet.toFixed(2)
                                    }}</strong
                                  ></u
                                ></span
                              >
                            </div>
                          </v-col> -->
                          <v-row class="no-gutters py-2">
                            <v-col md="6">
                              <h5>FirstName :</h5>
                              <v-text-field
                                v-model="customDetails.firstname"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>LastName :</h5>
                              <v-text-field
                                v-model="customDetails.lastname"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <v-col md="12">
                              <h5>Phone :</h5>
                              <v-text-field
                                v-model="customDetails.phone"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <v-col md="12">
                              <h5>Email :</h5>
                              <v-text-field
                                v-model="customDetails.email"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>Raiting :</h5>
                              <v-text-field
                                v-model="customDetails.rating"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>Block/Permit :</h5>
                              <v-text-field
                                v-model="customDetails.isBanned"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>Type :</h5>
                              <v-text-field
                                v-model="customDetails.type"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>Status :</h5>
                              <v-text-field
                                v-model="customDetails.status"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px;"
                                readonly
                              />
                            </v-col>
                            <!-- <v-col md="6">
                              <v-checkbox
                              v-model="customDetails.is_featured"
                              label="Featured"
                              readonly
                            />
                            </v-col>
                            <v-col md="12">
                              <h5>Bio</h5>
                              <v-textarea
                                readonly
                                rows="8"
                                :value="customDetails.bio"
                              ></v-textarea>
                            </v-col> -->
                          </v-row>
                        </template>
                      </v-form>
                    </template>
                    <v-btn
                      color="success"
                      class="mr-0"
                      @click="enableEditUserDetail()"
                      v-if="userDetailsEdit != true"
                    >
                      Edit
                    </v-btn>
                    <v-btn
                      color="success"
                      class="mr-0"
                      @click="updateAction()"
                      v-if="userDetailsEdit"
                      :loading="generealDetailsSaveBtnLd"
                    >
                      Save
                    </v-btn>
                  </v-card>
                </base-material-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  getCustomer,
  getSingleUserMeetingStats,
  getUserEarning,
  getInvitVehiclesers,
  getIsUserMakeFirstBooking,
  updateFeatured,
  getUserSingleMetadata,
  updateUser,
  getCustomerVehicle,
  getExperienceByID,
  updateUserVehiclecation,
  updateUserExperience,
  createUserVehiclecation,
  createUserExperience,
  deleteUserExperience,
  deleteUserVehiclecation,
} from '@/services/users'
import {
  addPhoto,
  updateCustomerVehicle,
  addNewVehicle,
  deleteCustomerVehicle,
  beingAdminUpdateCustomer,
  getAllCustomerRecentBooking,
  uploadImage,
} from '@/services/admin'
import Icons from '../dashboard/component/Icons.vue'
import VComponent from '../../components/base/VComponent.vue'
import DateFilter from '@/components/dateFilter'
import { http } from '@/services/http-common'
import Bookings from '@/components/bookings'

export default {
  components: { Icons, VComponent, DateFilter },
  data() {
    return {
      selectedNewAvatarImage: null,
      generealDetailsSaveBtnLd: false,
      dialog: false,
      menu: false,
      menuE: false,
      load: false,
      showLoad: false,
      timeStatus: 'all',
      activePicker: null,
      activePickerE: null,
      menuexp: false,
      menuexpend: false,
      activePickerexp: null,
      activePickerexpend: null,
      dialogDelete: false,
      headers: [
        {
          text: 'Pickup Address',
          value: 'pickupAdr',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Dropoff Address',
          value: 'dropoffAdr',
          width: '100px',
          sortable: false,
        },
        // {
        //   text: "Title",
        //   align: "start",
        //   sortable: false,
        //   value: "title",
        //   width: "100px",
        // },

        {
          text: 'Vehicle Make',
          value: 'vehicleMake',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Registration No#',
          value: 'vehicleRegistration',
          width: '100px',
          sortable: false,
        },

        // { text: 'Employement Type', value: 'employement_type', width: '170px', sortable: false },
        {
          text: 'Status',
          value: 'rideStatus',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Start Date',
          value: 'createdAt',
          width: '100px',
          sortable: false,
        },
        // {
        //   text: "End Date",
        //   value: "end_date",
        //   width: "100px",
        //   sortable: false,
        // },
        // {
        //   text: "Currently Working",
        //   value: "current_job",
        //   width: "150px",
        //   sortable: false,
        // },
        // {
        //   text: "Description",
        //   value: "description",
        //   width: "190px",
        //   sortable: false,
        // },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      bookings: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        title: '',
        image_exp: '',
        job: '',
        location: '',
        company: '',
        employement_type: '',
        current_job: '',
        status: '',
        start_date: '',
        end_date: '',
        description: '',
      },
      defaultItem: {
        id: '',
        title: '',
        image_exp: '',
        job: '',
        location: '',
        company: '',
        employement_type: '',
        current_job: '',
        status: '',
        start_date: '',
        end_date: '',
        description: '',
      },
      dialogVehicle: false,
      dialogDeleteVehicle: false,
      headersVehicle: [
        {
          text: 'Make',
          align: 'start',
          sortable: false,
          value: 'make',
          width: '170px',
        },
        {
          text: 'Registration',
          value: 'registration',
          sortable: false,
          width: '170px',
        },
        {
          text: 'Created At',
          value: 'createdAt',
          width: '140px',
          sortable: false,
        },
        {
          text: 'updated At',
          value: 'updatedAt',
          width: '140px',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false, width: '100px' },
      ],
      VehicleDetails: [],
      editedIndexVehicle: -1,
      editedItemVehicle: {
        id: '',
        make: '',
        registration: '',
        createdAt: '',
        updatedAt: '',
      },
      defaultItemVehicle: {
        id: '',
        make: '',
        registration: '',
        createdAt: '',
        updatedAt: '',
      },
      dialogInvt: false,
      dialogDeleteInvt: false,
      headersInvt: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
          width: '100px',
        },
        {
          text: 'Last Name',
          align: 'start',
          sortable: false,
          value: 'lastName',
          width: '100px',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
          width: '140px',
        },
        {
          text: 'First Booking',
          align: 'start',
          sortable: false,
          value: 'firstBooking',
          width: '140px',
        },
        { text: 'Actions', value: 'actions', sortable: false, width: '100px' },
      ],
      InvtDetails: [],
      editedIndexInvt: -1,
      editedItemInvt: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        firstBooking: '',
      },
      defaultItemInvt: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        firstBooking: '',
      },
      userDetails: null,
      userStats: null,
      userEarning: null,
      userVehicle: null,
      userInvt: null,
      userExp: null,
      userBooking: null,
      showFileInput: false,
      showFileInputVehicle: false,
      showFileInputExp: false,
      userDetailsEdit: false,
      dateFilter: null,
      initDate: [],
    }
  },
  computed: {
    formTitleVehicle() {
      return this.editedIndexVehicle === -1 ? 'New Item' : 'Edit Item'
    },
    formTitleInvt() {
      return this.editedIndexVehicle === -1 ? 'New Item' : 'Edit Item'
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    customDetails() {
      return {
        profile_photo: this.userDetails.avatar,
        firstname: this.userDetails.firstName,
        lastname: this.userDetails.surName,
        email: this.userDetails.email,
        phone: this.userDetails.phone,
        type: this.userDetails.role,
        status: this.userDetails.isVerified ? 'Approved' : 'Pending',
        // is_featured: this.userDetails.is_featured,
        availability: this.userDetails.is_busy ? 'Busy' : 'Available',
        rating: this.userDetails.rating.currentRating,
        login_type: this.userDetails.method,
        wallet: this.userDetails.wallet.balance,
        isBanned: this.userDetails.banned ? 'Blocked' : 'Permitted',
        // bio: this.userDetails.bio,
      }
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    menuE(val) {
      val && setTimeout(() => (this.activePickerE = 'YEAR'))
    },
    menuexp(val) {
      val && setTimeout(() => (this.activePickerexp = 'YEAR'))
    },
    menuexpend(val) {
      val && setTimeout(() => (this.activePickerexpend = 'YEAR'))
    },
    dialogVehicle(val) {
      val || this.closeVehicle()
    },
    dialogDeleteVehicle(val) {
      val || this.closeDeleteVehicle()
    },
    dialogInvt(val) {
      val || this.closeInvt()
    },
    dialogDeleteInvt(val) {
      val || this.closeDeleteInvt()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    timeStatus() {
      this.getUsersTimeStats()
    },
  },
  mounted() {
    getCustomer(this.$route.params.id).then((res) => {
      this.userDetails = res.data.customer[0]
      console.log(this.userDetails, 'its ok not to be ok')
    })
    // getUserSingleMetadata(this.$route.params.id).then((res)=> {
    //   if(res.data.is_featured == 1){
    //     res.data['is_featured'] = true;
    //   }
    //   else{
    //    res.data['is_featured'] = false;
    //   }
    //   this.userDetails = {...res.data , ...this.userDetails}
    //   console.log('====================================');
    //   console.log(this.userDetails.is_featured);
    //   console.log('====================================');
    // })
    this.getUsersTimeStats()
    // getSingleUserMeetingStats(this.$route.params.id).then((res) => {
    //   this.userStats = res.data;
    // });

    getAllCustomerRecentBooking(this.$route.params.id).then((res) => {
      this.userBooking = res.data
      console.log('bookings', res.data)
      this.userBooking.forEach((ele) => {
        const row = {
          pickupAdr: ele.pickup.address,
          dropoffAdr: ele.dropoff.address,
          vehicleMake: ele.vehicleMake,
          vehicleRegistration: ele.vehicleRegistration,
          createdAt: ele.createdAt,
          rideStatus: ele.status,
        }
        this.bookings.push(row)
      })
    })

    // getExperienceByID(this.$route.params.id).then((res) => {
    //   this.userExp = res.data;
    //   console.log("11", res.data);
    //   this.userExp.forEach((ele) => {
    //     const row = {
    //       id: ele.id,
    //       title: ele.title,
    //       image_exp: ele.profile_photo,
    //       job: ele.job,
    //       location: ele.location,
    //       company: ele.company,
    //       employement_type: ele.employement_type,
    //       current_job: this.setcurrent_job(ele.current_job),
    //       status: ele.status,
    //       start_date: this.getReadableDate(ele.start_date),
    //       end_date: ele.end_date ? this.getReadableDate(ele.end_date) : null,
    //       description: ele.description,
    //     };
    //     this.desserts.push(row);
    //   });
    // });
    getCustomerVehicle(this.$route.params.id).then((res) => {
      this.userVehicle = res.data.customerVehicle
      this.showLoad = true

      this.userVehicle.forEach((element) => {
        const row = {
          id: element._id,
          make: element.make,
          registration: element.registration,
          createdAt: element.createdAt
            ? this.getReadableDate(element.createdAt)
            : null,
          updatedAt: element.updatedAt
            ? this.getReadableDate(element.updatedAt)
            : null,
        }
        this.VehicleDetails.push(row)
      })
      this.showLoad = false
    })
    // getInvitVehiclesers(this.$route.params.id).then(async (res) => {
    //   this.userInvt = res.data.usersArray;
    //   this.userInvt.forEach((element) => {
    //     const row = {
    //       id: element.dataValues.id,
    //       firstName: element.dataValues.first_name,
    //       lastName: element.dataValues.last_name,
    //       email: element.dataValues.email,
    //       firstBooking: element.isBooking ? "No" : "Yes",
    //     };
    //     this.InvtDetails.push(row);
    //   });
    // });
    // getUserEarning(this.$route.params.id).then((res) => {
    //   this.userEarning = res.data;
    // });
  },
  methods: {

    openFileInput() {
      this.$refs.avatarFileInput.click(); // Trigger the hidden file input for the avatar
    },
    onAvatarFileChange(event) {
      const file = event.target.files[0]; // Get the selected file from the avatar file input
      // Handle the file for the avatar separately here
      // For now, we will set it to the customDetails.profile_photo to display it in the avatar
      if (file) {
        this.selectedNewAvatarImage = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.customDetails.profile_photo = e.target.result;

          //below two lines using just a hack to rerender image because it was failing to rerender
          this.userDetailsEdit = false;
          this.userDetailsEdit = true;
          console.log('called');

        };
        reader.readAsDataURL(file);
      }
    },

    onDateChange(newValue) {
      if (newValue.length < 2) return
      this.dateFilter = newValue
      this.loadUserStats()
    },
    async getUsersTimeStats() {
      this.load = true
      this.showLoad = true
      const userStatsParams = {
        status: this.timeStatus === 'all' ? null : this.timeStatus,
        id: this.$route.params.id,
      }
      // await getUserEarning(userStatsParams).then((res) => {
      //   this.userEarning = res.data;
      //   this.userStats = res.data;
      //   console.log("test", this.userEarning);
      //   this.load = false;
      //   this.showLoad = false;
      // });
      // await getSingleUserMeetingStats(userStatsParams).then((res) => {
      //   this.userStats = res.data;
      // });
      this.showLoad = false
    },
    loadUserStats() {
      http
        .get(
          `${process.env.VUE_APP_API}/earning/2/?start=${this.dateFilter[0]}&end=${this.dateFilter[1]}`,
        )
        .then((res) => {
          this.userEarning = res.data
          this.userStats = res.data
        })
    },
    initialize() {
      this.userExp.forEach((ele) => {
        const row = {
          id: ele.id,
          title: ele.title,
          image: ele.profile_photo,
          job: ele.job,
          location: ele.location,
          company: ele.company,
          employement_type: ele.employement_type,
          current_job: this.setcurrent_job(ele.current_job),
          status: ele.status,
          start_date: ele.start_date,
          end_date: ele.end_date,
          description: ele.description,
        }
        this.desserts.push(row)
      })
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm(id) {
      this.desserts.splice(this.editedIndex, 1)
      deleteUserExperience(id).then((userExpres) => {
        alert('User Record Deleted Successfully!')
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const formDataExp = new FormData()
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
        formDataExp.append('file', this.editedItem.image_exp)
        formDataExp.append('experience_id', this.editedItem.id)
        this.editedItem.current_job = this.editedItem.current_job ? '1' : '0'
        console.log(this.editedItem.current_job)
        updateUserExperience(this.editedItem.id, this.editedItem).then(
          (userExpres) => {
            addPhoto('experience', formDataExp).then((res) => {
              window.location.reload()
            })
            alert('User Record Updated Successfully!')
          },
        )
      } else {
        this.desserts.push(this.editedItem)
        const formDataExpNew = new FormData()
        const newExp = {
          user_id: this.$route.params.id,
          title: this.editedItem.title,
          job: this.editedItem.job,
          image: this.editedItem.image_exp,
          location: this.editedItem.location,
          company: this.editedItem.company,
          employement_type: this.editedItem.employement_type,
          current_job: this.editedItem.current_job,
          status: this.editedItem.status,
          start_date: this.editedItem.start_date,
          end_date: this.editedItem.current_job
            ? null
            : this.editedItem.end_date,
          description: this.editedItem.description,
        }
        createUserExperience(newExp).then((userExpres) => {
          formDataExpNew.append('file', newExp.image)
          formDataExpNew.append('experience_id', userExpres.data.id)
          console.log(this.editedItemVehicle.image_Vehicle)
          addPhoto('experience', formDataExpNew).then((res) => {
            window.location.reload()
          })
          alert('User Record Updated Successfully!')
        })
      }
      this.close()
    },
    initializeVehicle() {
      this.userVehicle.forEach((element) => {
        const row = {
          school: element.school,
          field_of_study: element.field_of_study,
          degree: element.degree,
          status: element.status,
          start_date: element.start_Date,
          end_date: element.end_Date,
          description: element.description,
        }
        console.log(row)
        this.VehicleDetails.push(row)
      })
    },
    initializeInvt() {
      this.userInvt.forEach((element) => {
        const row = {
          firstName: element.first_name,
          lastName: element.last_name,
          email: element.email,
          firstBooking: this.isUserMakeFirstBooking(element.id),
        }
        console.log(row)
        this.InvtDetails.push(row)
      })
    },

    editItemVehicle(item) {
      this.editedIndexVehicle = this.VehicleDetails.indexOf(item)
      this.editedItemVehicle = Object.assign({}, item)
      this.dialogVehicle = true
    },
    editItemInvt(item) {
      this.editedIndexInvt = this.InvtDetails.indexOf(item)
      this.editedItemInvt = Object.assign({}, item)
      this.dialogInvt = true
    },
    goToProfile(id) {
      this.$router.push({ name: 'Customer Profile', params: { id: id } })
      window.location.reload()
    },
    deleteItemVehicle(item) {
      this.editedIndexVehicle = this.VehicleDetails.indexOf(item)
      this.editedItemVehicle = Object.assign({}, item)
      this.dialogDeleteVehicle = true
    },
    deleteItemInvt(item) {
      this.editedIndexInvt = this.InvtDetails.indexOf(item)
      this.editedItemInvt = Object.assign({}, item)
      this.dialogDeleteInvt = true
    },

    deleteItemConfirmVehicle(id) {
      console.log(id)
      this.VehicleDetails.splice(this.editedIndexVehicle, 1)
      deleteCustomerVehicle(id).then((userExpress) => {
        alert('User Record Deleted Successfully!')
      })
      // deleteUserVehiclecation(id).then((userExpres) => {
      //   alert("User Record Deleted Successfully!");
      // });
      this.closeDeleteVehicle()
    },
    deleteItemConfirmInvt(id) {
      console.log(id)
      this.InvtDetails.splice(this.editedIndexInvt, 1)
      deleteUserVehiclecation(id).then((userExpres) => {
        alert('User Record Deleted Successfully!')
      })
      this.closeDeleteInvt()
    },

    closeVehicle() {
      this.dialogVehicle = false
      this.$nextTick(() => {
        this.editedItemVehicle = Object.assign({}, this.defaultItemVehicle)
        this.editedIndexVehicle = -1
      })
    },
    closeInvt() {
      this.dialogInvt = false
      this.$nextTick(() => {
        this.editedItemInvt = Object.assign({}, this.defaultItemInvt)
        this.editedIndexInvt = -1
      })
    },

    closeDeleteVehicle() {
      this.dialogDeleteVehicle = false
      this.$nextTick(() => {
        this.editedItemVehicle = Object.assign({}, this.defaultItemVehicle)
        this.editedIndexVehicle = -1
      })
    },
    closeDeleteInvt() {
      this.dialogDeleteInvt = false
      this.$nextTick(() => {
        this.editedItemInvt = Object.assign({}, this.defaultItemInvt)
        this.editedIndexInvt = -1
      })
    },

    saveInvt() {
      if (this.editedIndexInvt > -1) {
        Object.assign(
          this.InvtDetails[this.editedIndexInvt],
          this.editedItemInvt,
        )
        alert('saved!')
      } else {
        this.InvtDetails.push(this.editedItemInvt)
        alert('saved!')
      }
      this.closeInvt()
    },

    async saveVehicle() {
      if (this.editedIndexVehicle > -1) {
        //editing vehicle details
        Object.assign(
          this.VehicleDetails[this.editedIndexVehicle],
          this.editedItemVehicle,
        )

        //prep obj for put reques
        const payload = {
          vehicleMake: this.editedItemVehicle.make,
          vehicleRegistration: this.editedItemVehicle.registration,
        }
        updateCustomerVehicle(this.editedItemVehicle.id, payload)
        alert('Vehicle information updated successfully.')
      } else {
        //adding new vehicle
        //prep obj for post request, in payload its user id
        const payload = {
          id: this.userDetails._id,
          vehicleMake: this.editedItemVehicle.make,
          vehicleRegistration: this.editedItemVehicle.registration,
        }
        await addNewVehicle(payload).then((res) => {
          alert('User Vehicle Added Successfully!')

          this.editedItemVehicle.createdAt = this.getReadableDate(
            res.data.vehicle.createdAt,
          )
          this.editedItemVehicle.updatedAt = this.getReadableDate(
            res.data.vehicle.updatedAt,
          )
        })
        this.VehicleDetails.push(this.editedItemVehicle)
      }
      this.closeVehicle()
    },
    getReadableDate(date) {
      const d = new Date(date).toUTCString()
      return date.substr(0, 7)
    },
    setcurrent_job(current_job) {
      if (current_job == 1) {
        return true
      } else {
        return false
      }
    },
    addProfilePhoto() {
      this.showFileInput = true
    },
    addPhotoVehicle() {
      this.showFileInputVehicle = true
    },
    addPhotoExp() {
      this.showFileInputExp = true
    },
    enableEditUserDetail() {
      this.userDetailsEdit = true
    },
    getShortAddress(address) {
      return (address.length > 15? `${address?.slice(0, 15)}...` : address)
    },
    updateAction() {
      const updateData = {
        userID: this.userDetails._id,
        firstName: this.customDetails.firstname,
        surName: this.customDetails.lastname,
        email: this.customDetails.email,
        phone: this.customDetails.phone,
        // rating: this.customDetails.rating,
        // availability: this.customDetails.availability
      }
      this.generealDetailsSaveBtnLd = true


      if (this.selectedNewAvatarImage && typeof this.selectedNewAvatarImage == 'object') {
        let formData = new FormData();
        formData.append('image', this.selectedNewAvatarImage);

        uploadImage(formData)
          .then((response) => {
            // console.log('=>', response?.data?.url);
            updateData.avatar = response?.data?.url;
            beingAdminUpdateCustomer(this.userDetails._id, updateData)
              .then(
                async (userDetailRes) => {
                  this.userDetailsEdit = false
                  alert('User Record Updated Successfully!')
                  this.generealDetailsSaveBtnLd = false
                })

          })
          .catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating User!");
          })

      } else {
        beingAdminUpdateCustomer(this.userDetails._id, updateData)
          .then(
            async (userDetailRes) => {
              this.userDetailsEdit = false
              alert('User Record Updated Successfully!')
            })
          .catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating User!");
          })
      }



    },
  },
}
</script>
<style>
.line-container {
  padding-left: 6rem !important;
}
.line-container .item-tag {
  width: 200px;
}
</style>
